import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import times from 'lodash/times';
import { useLazyQuery, useQuery } from '@apollo/client';

import { Fade } from '@mui/material';
import { PageWrapper } from '../components/partials';
import TagsVisibilityHeader from './components/tagsVisibilityHeader/TagsVisibilityHeader';
import TutorialSteps from './components/tutorialSteps/TutorialSteps';
import css from './page.css';
import EmptyStateIcon from '../resources/icons/creatives/empty_state.svg';
import NoDataIcon from '../resources/icons/creatives/no_data.svg';
import { CreativeFilters } from './creativesFiltersUtils';
import { useAsync } from '../utils/customHooks';
import CreativePopup from './components/creativePopup/CreativePopup';
import { Reports } from '../services';
import {
    GET_CREATIVE_TAGS,
    GET_DIMENSIONS_BY_CATEGORY,
    GET_USER_APPS,
    GET_USER_METRICS,
    GET_USER_SOURCES,
} from './queries';
import DimensionsFilter from './components/dimensionFilter/DimensionsFilter';
import { getMainCreativesQueryParams, getMultiValuedDimensionsQueryParams } from './creativesQueryUtils';
import { formatCreatives, getOptionsList } from './creativesFormattingUtils';
import CreativesLayout from './components/creativesLayout/CreativesLayout';
import {
    AIDimensionsContext,
    DEFAULT_END_DATE,
    DEFAULT_METRICS,
    DEFAULT_START_DATE,
    SOURCES_OPTIONS,
    TRACK_EVENT_PREFIX,
} from './consts';
import { isObjectEmpty, trackMixpanelEvent } from '../utils/general';
import { downloadCSV } from './csvUtils';
import {
    ALL_METRICS_OPTIONS,
    CREATIVES_COHORT_METRICS_OPTIONS,
    CREATIVES_CORE_METRICS_OPTIONS,
    getAvailableMetricsOptions,
    SORTED_METRICS_OPTIONS,
} from './metricsUtils';
import { isCreativesGalleryReportRunning } from '../actions/reports';
import { useUrlParams } from '../utils/useUrlParamsHook';

const STEPS_AMOUNT = 3;
export const EMPTY_STATE_PARAMS = {
    icon: <EmptyStateIcon className={css.tutorialIcon} />,
    title: 'STATIC.PAGES.TAGS_VISIBILITY.EMPTY_STATE.TITLE',
    steps: times(STEPS_AMOUNT, index => `STATIC.PAGES.TAGS_VISIBILITY.EMPTY_STATE.STEPS.${index + 1}`),
};

export const NO_DATA_PARAMS = {
    icon: <NoDataIcon className={css.tutorialIcon} />,
    title: 'STATIC.PAGES.TAGS_VISIBILITY.NO_DATA.TITLE',
    steps: times(STEPS_AMOUNT, index => `STATIC.PAGES.TAGS_VISIBILITY.NO_DATA.STEPS.${index + 1}`),
};

const ReportsAPI = new Reports();

const TagsVisibility = () => {
    const dispatch = useDispatch();
    const { getParamsObject, setParamsObject, removeParam } = useUrlParams();
    const gridRef = useRef(null);
    const shouldCancelTagsFetch = useRef(false);
    const pageLoadingStartTime = useRef(new Date());

    const [dateRange, setDateRange] = useState({
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE,
    });

    const [app, setApp] = useState();
    const [source, setSource] = useState();
    const [selectedMetrics, setSelectedMetrics] = useState(DEFAULT_METRICS);
    const [viewedCreativeImageHash, setViewedCreativeImageHash] = useState();
    const [filteredCreatives, setFilteredCreatives] = useState();
    const [creativesTags, setCreativesTags] = useState();

    const filterToSetterMap = {
        [CreativeFilters.APP]: setApp,
        [CreativeFilters.SOURCE]: setSource,
        [CreativeFilters.METRICS]: metrics =>
            setSelectedMetrics(ALL_METRICS_OPTIONS.filter(metric => metrics.includes(metric.value))),
        [CreativeFilters.DATE_RANGE]: setDateRange,
    };

    const { loading: isLoadingSources, data: sourcesResponse } = useQuery(GET_USER_SOURCES, {
        variables: { filteredSourcesNames: SOURCES_OPTIONS },
    });

    const { loading: isLoadingApps, data: appsResponse } = useQuery(GET_USER_APPS);

    const { data: metricsResponse } = useQuery(GET_USER_METRICS);

    const [getCreativesTags, { data: creativesTagsResponse, loading: isLoadingCreativesTags }] = useLazyQuery(
        GET_CREATIVE_TAGS
    );

    const [getDimensionsByCategory, { data: dimensionsByCategoryResponse }] = useLazyQuery(GET_DIMENSIONS_BY_CATEGORY);

    const dimensionsByCategory = useMemo(() => JSON.parse(dimensionsByCategoryResponse?.dimensionsByCategory ?? '{}'), [
        dimensionsByCategoryResponse,
    ]);

    const availableMetrics = useMemo(() => {
        if (!metricsResponse) return null;

        const { metrics: userMetrics, allCohortMetrics } = metricsResponse.userMetrics;
        return {
            metrics: getAvailableMetricsOptions(userMetrics, CREATIVES_CORE_METRICS_OPTIONS),
            cohortMetrics: getAvailableMetricsOptions(allCohortMetrics, CREATIVES_COHORT_METRICS_OPTIONS),
        };
    }, [metricsResponse]);

    const canQueryCreatives = !!(dateRange && app && source && availableMetrics && selectedMetrics.length);

    // The creatives query is seperated for 2 queries - one for the dimensions with the unique values and another for
    // the ones which are multivalued. We do that in order to avoid getting duplicate creatives, which can happen when
    // querying a dimension which can have multiple values for the same creative
    const [{ data: creativesResponse, isLoading }, runCreativesFetch] = useAsync();
    const [
        { data: creativesMultiValuedDimensionsResponse, isLoading: isLoadingDimensions },
        runCreativesMultiValuedDimensionsFetch,
    ] = useAsync();

    useEffect(() => {
        // When we switch the filters when a csv export is loading, the response still comes back even though its
        // irrelevant, in these cases we use a var saying we need to skip the response received
        if (shouldCancelTagsFetch.current) {
            shouldCancelTagsFetch.current = false;
            return;
        }

        if (creativesTagsResponse) {
            setCreativesTags(creativesTagsResponse.creativeTags.tags);
        }
    }, [creativesTagsResponse]);

    useEffect(() => {
        app && getDimensionsByCategory({ variables: { appId: app } });
    }, [getDimensionsByCategory, app]);

    useEffect(() => {
        dispatch(isCreativesGalleryReportRunning(isLoading));
    }, [isLoading]);

    const formattedUrlSelectedMetrics = useMemo(() => {
        return selectedMetrics.map(({ value }) => value);
    }, [selectedMetrics]);

    const fetchCreativesData = () => {
        if (!canQueryCreatives) return;

        setParamsObject({
            app,
            source,
            metrics: formattedUrlSelectedMetrics,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        });

        runCreativesFetch(
            ReportsAPI.runQuery(getMainCreativesQueryParams(dateRange, app, source, availableMetrics), {
                suffix: 'base',
            })
        );
        runCreativesMultiValuedDimensionsFetch(
            ReportsAPI.runQuery(getMultiValuedDimensionsQueryParams(dateRange, app, source, availableMetrics), {
                suffix: 'multiValued',
            })
        );
    };

    // Load creatives when filters are changed.
    useEffect(() => {
        fetchCreativesData();
    }, [dateRange, app, source, availableMetrics]);

    // Load creatives when metrics are selected for the first time.
    useEffect(() => {
        if (!creativesResponse && !isLoading && !creativesMultiValuedDimensionsResponse && !isLoadingDimensions) {
            fetchCreativesData();
        } else if (canQueryCreatives) {
            setParamsObject({ metrics: formattedUrlSelectedMetrics });
        }
    }, [selectedMetrics]);

    const onFilterChange = (field, value) => {
        if (field === CreativeFilters.METRICS) {
            trackMixpanelEvent(TRACK_EVENT_PREFIX, 'Metrics Filter Changed', {
                app,
                source,
            });
        }

        filterToSetterMap[field](value);
        setCreativesTags(undefined);

        if (isLoadingCreativesTags) {
            shouldCancelTagsFetch.current = true;
        }
    };

    useEffect(() => {
        if (isLoadingApps || isLoadingSources) return;

        const loadingTime = `${new Date().getMilliseconds() -
            pageLoadingStartTime.current.getMilliseconds()} milliseconds`;

        trackMixpanelEvent(TRACK_EVENT_PREFIX, 'Page Loaded', { loadingTime });
        pageLoadingStartTime.current = null;

        const urlParams = getParamsObject();

        if (isObjectEmpty(urlParams)) return;

        const {
            app: urlApp,
            source: urlSource,
            metrics: urlMetrics,
            startDate: urlStartDate,
            endData: urlEndDate,
            creativeHash: urlCreativeHash,
        } = urlParams;

        if (urlApp) onFilterChange(CreativeFilters.APP, urlApp);
        if (urlSource) onFilterChange(CreativeFilters.SOURCE, urlSource);
        if (urlMetrics) onFilterChange(CreativeFilters.METRICS, urlMetrics);
        if (urlStartDate && urlEndDate)
            onFilterChange(CreativeFilters.DATE_RANGE, { startDate: urlStartDate, endDate: urlEndDate });

        if (urlCreativeHash) setViewedCreativeImageHash(urlCreativeHash);
    }, [isLoadingApps, isLoadingSources]);

    useEffect(() => {
        const creativeHashParam = 'creativeHash';

        if (viewedCreativeImageHash) {
            setParamsObject({ [creativeHashParam]: viewedCreativeImageHash });
        } else {
            removeParam(creativeHashParam);
        }
    }, [viewedCreativeImageHash]);

    const creatives = useMemo(
        () =>
            formatCreatives(
                creativesResponse?.results,
                selectedMetrics,
                creativesMultiValuedDimensionsResponse?.results
            ),
        [creativesResponse?.results, selectedMetrics, creativesMultiValuedDimensionsResponse?.results]
    );

    const appsOptions = useMemo(() => getOptionsList(appsResponse?.userApps || [], 'icon'), [appsResponse?.userApps]);
    const sourcesOptions = useMemo(() => getOptionsList(sourcesResponse?.userSources || [], 'logoPath'), [
        sourcesResponse?.userSources,
    ]);

    const viewedCreative = viewedCreativeImageHash
        ? creatives.find(({ imageHash }) => imageHash === viewedCreativeImageHash)
        : null;

    const selectedAppOption = appsOptions?.find(option => option.value === app);
    const selectedSourceOption = sourcesOptions?.find(option => option.value === source);

    useEffect(() => {
        if (isLoadingCreativesTags || !creativesTags || !selectedAppOption || !selectedSourceOption) return;

        downloadCSV(creatives, creativesTags, selectedAppOption.label, selectedSourceOption.label, dateRange);
    }, [creativesTags, isLoadingCreativesTags]);

    const onCsvDownloadClicked = () => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, 'CSV Export Clicked', {
            app,
            source,
        });

        if (creativesTags) {
            downloadCSV(creatives, creativesTags, selectedAppOption.label, selectedSourceOption.label, dateRange);
        } else {
            getCreativesTags({
                variables: { imageHashes: creatives.map(creative => creative.imageHash), appId: app },
            });
        }
    };

    const onSortChange = metricValue => {
        const metricOption = ALL_METRICS_OPTIONS.find(metric => metric.value === metricValue);
        if (selectedMetrics.some(metric => metric.value === metricOption.value)) return;

        filterToSetterMap[CreativeFilters.METRICS]([...selectedMetrics, metricOption].map(({ value }) => value));
    };

    return (
        <AIDimensionsContext.Provider value={{ aiDimensionsByCategory: dimensionsByCategory, creativesTags }}>
            <PageWrapper className={css.pageWrapper} disablePadding>
                <TagsVisibilityHeader
                    apps={appsOptions}
                    sources={sourcesOptions}
                    metrics={SORTED_METRICS_OPTIONS}
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    app={app}
                    source={source}
                    isLoadingApps={isLoadingApps}
                    isLoadingSources={isLoadingSources}
                    onFilterChange={onFilterChange}
                    selectedMetrics={selectedMetrics}
                    creativesAmount={creatives.length}
                    onCsvDownloadClicked={onCsvDownloadClicked}
                    hasQueryBeenDone={canQueryCreatives && !!creativesResponse}
                    isLoadingCsvExport={isLoadingCreativesTags}
                />
                <div className={css.content} onScroll={e => gridRef.current?.onScroll(e)}>
                    <DimensionsFilter
                        app={app}
                        source={source}
                        creatives={creatives}
                        disabled={!canQueryCreatives || isLoading || !creatives.length}
                        onFilteredCreativesChange={setFilteredCreatives}
                        isLoadingMultiGeneralDimensions={isLoadingDimensions}
                    />
                    {!canQueryCreatives ? (
                        <TutorialSteps {...EMPTY_STATE_PARAMS} />
                    ) : !creatives.length && !isLoading ? (
                        <TutorialSteps {...NO_DATA_PARAMS} />
                    ) : (
                        <CreativesLayout
                            ref={gridRef}
                            isLoading={isLoading}
                            isLoadingDimensions={isLoadingDimensions}
                            creatives={filteredCreatives}
                            onCreativeClick={setViewedCreativeImageHash}
                            selectedMetrics={selectedMetrics}
                            metricsOptions={SORTED_METRICS_OPTIONS}
                            onSortChange={onSortChange}
                        />
                    )}
                </div>
            </PageWrapper>
            <Fade in={!!viewedCreative}>
                <div className={css.creativePopupWrapper}>
                    {viewedCreative && (
                        <CreativePopup
                            app={selectedAppOption}
                            source={selectedSourceOption}
                            creative={viewedCreative}
                            onClose={() => setViewedCreativeImageHash(null)}
                        />
                    )}
                </div>
            </Fade>
        </AIDimensionsContext.Provider>
    );
};

TagsVisibility.propTypes = {};

TagsVisibility.defaultProps = {};

export default TagsVisibility;
