import { useSearchParams } from 'react-router-dom';

/*
 * Custom hook for managing URL parameters in a JSON structure
 */
export function useUrlParams() {
    const [searchParams, setSearchParams] = useSearchParams();

    // Parse parameters from URL
    const getParamsObject = () => {
        const params = {};

        searchParams.forEach((value, key) => {
            params[key] = JSON.parse(value);
        });

        return params;
    };

    // Update URL parameters by merging with existing ones
    const setParamsObject = params => {
        Object.entries(params).forEach(([key, value]) => {
            searchParams.set(key, JSON.stringify(value));
        });

        setSearchParams(searchParams);
    };

    const removeParam = param => {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete(param);
        setSearchParams(newParams);
    };

    return {
        getParamsObject,
        setParamsObject,
        removeParam,
    };
}
